var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "guestbox" },
    [
      _vm._l(_vm.sections, function(l, what) {
        return _c("tr", { staticClass: "gb-row guest-people-rows" }, [
          _c("td", { staticClass: "gb-td label" }, [
            _c("label", [_vm._v(_vm._s(_vm.getFieldLabel(what)))]),
            _vm._v(" "),
            what === "adults"
              ? _c("div", { staticClass: "age" }, [
                  _vm._v(_vm._s(_vm.voMsg("tpl.adults.age")))
                ])
              : _vm._e(),
            _vm._v(" "),
            what === "children"
              ? _c("div", { staticClass: "age" }, [
                  _vm._v(" " + _vm._s(_vm.voMsg("tpl.children.age")))
                ])
              : _vm._e(),
            _vm._v(" "),
            what === "babys"
              ? _c("div", { staticClass: "age" }, [
                  _vm._v(_vm._s(_vm.voMsg("tpl.baby.age")))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("td", { staticClass: "gb-td" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default btn-minus",
                attrs: {
                  "aria-label": _vm.labelMinus(),
                  disabled: !_vm.canDecr(what),
                  type: "button"
                },
                on: {
                  click: function($event) {
                    return _vm.inc(what, -1)
                  }
                }
              },
              [_c("i", { staticClass: "fa-sharp fa-light fa-minus icon" })]
            )
          ]),
          _vm._v(" "),
          _c("td", { staticClass: "gb-td" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default btn-plus",
                attrs: {
                  "aria-label": "labelPlus()",
                  disabled: !_vm.canInc(what),
                  type: "button"
                },
                on: {
                  click: function($event) {
                    return _vm.inc(what, 1)
                  }
                }
              },
              [_c("i", { staticClass: "fa-sharp fa-light fa-plus icon" })]
            )
          ])
        ])
      }),
      _vm._v(" "),
      _vm.maxbel
        ? _c("tr", [
            _c("td", { staticClass: "max-bel", attrs: { colspan: "3" } }, [
              _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.voMsg("tpl.text.maxOccupancyReached")) +
                    "\n            "
                )
              ])
            ])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }