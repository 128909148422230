const {
	href, origin, pathname, search
} = window.location;

const redirects = new Map([
	["/de/01-ahlbeck-ferienhaus-ahlbeck-mit-sauna?id=89072", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Ahlbeck-.Ferienhaus"],
	["/de/01-ahlbeck-ferienhaus-kieferngrund-mit-sauna?id=88729", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Kieferngrund-.Haus"],
	["/de/01-ahlbeck-ferienwohnung-fast?id=156865", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Fast"],
	["/de/01-ahlbeck-ferienwohnung-kieferngrund?id=88909", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Kieferngrund-.FeWo"],
	["/de/01-ahlbeck-ferienwohnung-kira?id=97749", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Kira"],
	["/de/01-ahlbeck-gartenoase-01?id=40639", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Gartenoase"],
	["/de/01-ahlbeck-haus-meerblick-whg-04?id=33794", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Haus.Meerblick-.04"],
	["/de/01-ahlbeck-haus-meerblick-whg-10?id=33795", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Haus.Meerblick-.10"],
	["/de/01-ahlbeck-haus-meerblick-whg-12?id=33797", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Haus.Meerblick-.12"],
	["/de/01-ahlbeck-haus-meerblick-whg-14?id=33800", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Haus.Meerblick-.14"],
	["/de/01-ahlbeck-haus-meerblick-whg-15?id=33804", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Haus.Meerblick-.15"],
	["/de/01-ahlbeck-haus-meerblick-whg-17?id=150529", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Haus.Meerblick-.17"],
	["/de/01-ahlbeck-lieblingssuite-am-strand?id=130450", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Lieblingssuite"],
	["/de/01-ahlbeck-lutt-matt-n?id=33863", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Luett-Mattn"],
	["/de/01-ahlbeck-villa-ahlbeck-whg-kuhlungsborn?id=81562", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Villa.Ahlbeck-.Kuehlungsborn"],
	["/de/01-ahlbeck-villa-ahlbeck-whg-warnemunde?id=80886", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Villa.Ahlbeck-.Warnemuende"],
	["/de/01-ahlbeck-villa-bellevue-app-8?id=89950", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Bellevue-.08"],
	["/de/01-ahlbeck-villa-bismarckshohe-wohnung-alma?id=137063", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Bismarckshoehe-.Alma"],
	["/de/01-ahlbeck-villa-bismarckshohe-wohnung-clara?id=137067", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Bismarckshoehe-.Clara"],
	["/de/01-ahlbeck-villa-bismarckshohe-wohnung-elfriede?id=137022", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Bismarckshoehe-.Elfriede"],
	["/de/01-ahlbeck-villa-bismarckshohe-wohnung-gertrud?id=137026", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Bismarckshoehe-.Gertrud"],
	["/de/01-ahlbeck-villa-bismarckshohe-wohnung-helene?id=137053", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Bismarckshoehe-.Helene"],
	["/de/01-ahlbeck-villa-bismarckshohe-wohnung-wanda?id=137013", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Bismarckshoehe-.Wanda"],
	["/de/01-ahlbeck-villa-germania-fewo-5?id=150720", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ahlbeck/Germania-.05"],
	["/de/02-heringsdorf-am-sonnenblick-whg-1?id=157583", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnenblick-.01"],
	["/de/02-heringsdorf-am-sonnenblick-whg-2?id=158125", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnenblick-.02"],
	["/de/02-heringsdorf-am-sonnenblick-whg-3?id=158134", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnenblick-.03"],
	["/de/02-heringsdorf-am-sonnenblick-whg-4?id=158267", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnenblick-.04"],
	["/de/02-heringsdorf-am-sonnenblick-whg-5?id=158268", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnenblick-.05"],
	["/de/02-heringsdorf-am-sonnenblick-whg-6?id=158270", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnenblick-.06"],
	["/de/02-heringsdorf-am-sonnengarten-whg-1?id=156954", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnengarten-.01"],
	["/de/02-heringsdorf-am-sonnengarten-whg-2?id=157054", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnengarten-.02"],
	["/de/02-heringsdorf-am-sonnengarten-whg-3?id=157055", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnengarten-.03"],
	["/de/02-heringsdorf-am-sonnengarten-whg-4?id=157069", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnengarten-.04"],
	["/de/02-heringsdorf-delbruck-villen-111?id=134491", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Delbrueck.Villen-.111"],
	["/de/02-heringsdorf-ferienwohnung-beier-oben?id=146973", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Beier.2-.oben"],
	["/de/02-heringsdorf-ferienwohnung-beier-unten?id=142159", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Beier.1-.unten"],
	["/de/02-heringsdorf-ferienwohnung-johanna?id=116022", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Johanna"],
	["/de/02-heringsdorf-ferienwohnung-marie-1?id=138318", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Marie-.01"],
	["/de/02-heringsdorf-ferienwohnung-marie-2?id=123620", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Marie-.02"],
	["/de/02-heringsdorf-ferienwohnung-marie-3?id=123641", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Marie-.03"],
	["/de/02-heringsdorf-ferienwohnung-sonnenkinder?id=154009", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sonnenkinder"],
	["/de/02-heringsdorf-godewind?id=141633", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Godewind"],
	["/de/02-heringsdorf-haus-henny-mit-sauna-und-kamin?id=127688", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Henny"],
	["/de/02-heringsdorf-ostseeliebe-mit-infrarot-sauna?id=116543", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Ostseeliebe-.FeWo"],
	["/de/02-heringsdorf-residenz-bellevue?id=45299", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Residenz.Bellevue-.07"],
	["/de/02-heringsdorf-strandoase-whg-21?id=37278", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Strandoase-.21"],
	["/de/02-heringsdorf-sunset?id=141665", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Sunset"],
	["/de/02-heringsdorf-villa-emmi?id=154198", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Villa.Emmi-.08"],
	["/de/02-heringsdorf-villa-inge-whg-4?id=141860", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Inge-.04"],
	["/de/02-heringsdorf-villa-inge-whg-6?id=80820", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Inge-.06"],
	["/de/02-heringsdorf-villa-marfa-app-3?id=35430", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Marfa-.03"],
	["/de/02-heringsdorf-villa-minheim-whg-4?id=51121", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Minheim-.04"],
	["/de/02-heringsdorf-villen-am-goethepark-villa-christiane-whg-8?id=140841", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Christiane-.08"],
	["/de/02-heringsdorf-waterfront-captain-s-haus-1-2?id=133661", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Waterfront-.1-2"],
	["/de/02-heringsdorf-waterfront-galeone-whg-4-2?id=54374", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Waterfront-.4-2"],
	["/de/02-heringsdorf-waterfront-galeone-whg-40-bernsteinkajute?id=148218", "/s"],
	["/de/02-heringsdorf-waterfront-karavelle-whg-3-0?id=46951", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Waterfront-.3-0"],
	["/de/02-heringsdorf-waterfront-karavelle-whg-3-5?id=46809", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/Waterfront-.3-5"],
	["/de/03-bansin_schloonseeapartements-sa_04?id=140310", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.04"],
	["/de/03-bansin_schloonseeapartements-sa_05?id=140327", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.05"],
	["/de/03-bansin_schloonseeapartements-sa_06?id=140333", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.06"],
	["/de/03-bansin_schloonseeapartements-sa_07?id=155462", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.07"],
	["/de/03-bansin_schloonseeapartements-sa_10?id=140350", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.10"],
	["/de/03-bansin_schloonseeapartements-sa_20?id=140354", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.20"],
	["/de/03-bansin-ferienhaus-mona?id=134006", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Mona"],
	["/de/03-bansin-ferienhaus-renate-wohnung-1?id=147149", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Renate-.01"],
	["/de/03-bansin-ferienhaus-renate-wohnung-2?id=155269", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Renate-.02"],
	["/de/03-bansin-ferienhaus-renate-wohnung-3?id=147151", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Renate-.03"],
	["/de/03-bansin-ferienhaus-renate-wohnung-4?id=147153", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Renate-.04"],
	["/de/03-bansin-ferienhaus-thorina?id=157420", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Thorina"],
	["/de/03-bansin-golz-am-see-01?id=122434", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Golz-.01"],
	["/de/03-bansin-golz-am-see-02?id=122438", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Golz-.02"],
	["/de/03-bansin-golz-am-see-03?id=122439", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Golz-.03"],
	["/de/03-bansin-golz-am-see-04?id=122440", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Golz-.04"],
	["/de/03-bansin-golz-am-see-05?id=122441", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Golz-.05"],
	["/de/03-bansin-golz-am-see-06?id=122442", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Golz-.06"],
	["/de/03-bansin-haus-auguste-app-16?id=33787", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Haus.Auguste-.16"],
	["/de/03-bansin-haus-barnheide-wohnung-sandburg?id=135096", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Barnheide-.Sandburg"],
	["/de/03-bansin-haus-barnheide-wohnung-silbermowe?id=135098", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Barnheide-.Silbermoewe"],
	["/de/03-bansin-haus-barnheide-wohnung-strandkorb?id=135109", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Barnheide-.Strandkorb"],
	["/de/03-bansin-haus-rolandseck-whg-03?id=33792", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Rolandseck-.03"],
	["/de/03-bansin-haus-rolandseck-whg-06?id=33803", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Rolandseck-.06"],
	["/de/03-bansin-haus-sternstunden?id=92915", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Sternstunden"],
	["/de/03-bansin-haus-wellenreiter?id=92913", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Wellenreiter"],
	["/de/03-bansin-schloonseevillen-sv_13?id=35437", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.13"],
	["/de/03-bansin-schloonseevillen-sv_17?id=96697", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.17"],
	["/de/03-bansin-schloonseevillen-sv_19?id=157578", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.19"],
	["/de/03-bansin-schloonseevillen-sv_25?id=112374", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/SV-.25"],
	["/de/03-bansin-seehof-whg-1-kleiner?id=33950", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Seehof.-kleiner--.01"],
	["/de/03-bansin-seehof-wohnung-1-33?id=70277", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Seehof-.1-33"],
	["/de/03-bansin-seepark-1112?id=102190", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Seepark-.703"],
	["/de/03-bansin-seepark-703-apartment-traful?id=127905", "/s"],
	["/de/03-bansin-seepark-704?id=102200", "/s"],
	["/de/03-bansin-seepark-705?id=102196", "/s"],
	["/de/03-bansin-seepark-808?id=98761", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Seepark-.808"],
	["/de/03-bansin-villa-anna-whg-amethyst?id=81557", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Villa.Anna-.Amethyst"],
	["/de/03-bansin-villa-anna-whg-bernstein?id=114682", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Villa.Anna-.Bernstein"],
	["/de/03-bansin-villa-anna-whg-rubin?id=42297", "/s"],
	["/de/03-bansin-villa-cremona-fewo-1?id=144046", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Cremona-.01"],
	["/de/03-bansin-villa-cremona-fewo-2?id=144048", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Cremona-.02"],
	["/de/03-bansin-villa-cremona-fewo-3?id=144051", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Cremona-.03"],
	["/de/03-bansin-villa-cremona-fewo-4?id=144053", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Cremona-.04"],
	["/de/03-bansin-villa-exss-whg-harmonie?id=93997", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Exss-.Harmonie"],
	["/de/03-bansin-villa-frieda-whg-1?id=141878", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Frieda-.01"],
	["/de/03-bansin-villa-frieda-whg-2?id=80819", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Frieda-.02"],
	["/de/03-bansin-villa-frieda-whg-4?id=157051", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Frieda-.04"],
	["/de/03-bansin-villa-frieda-whg-6?id=68490", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Frieda-.06"],
	["/de/03-bansin-villa-frieda-whg-8?id=68492", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Frieda-.08"],
	["/de/03-bansin-villa-frisia-whg-31?id=103332", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Frisia-.31"],
	["/de/03-bansin-villa-frohsinn-whg-7?id=96716", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Frohsinn-.07"],
	["/de/03-bansin-villa-malve-wohnung-7?id=154792", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Malve-.07"],
	["/de/03-bansin-villa-malve-wohnung-8?id=154798", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Malve-.08"],
	["/de/03-bansin-villa-malve-wohnung-9?id=154801", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Malve-.09"],
	["/de/03-bansin-villa-margot-01?id=33777", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Villa.Margot-.01"],
	["/de/03-bansin-villa-margot-02?id=33806", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Villa.Margot-.02"],
	["/de/03-bansin-villa-margot-10?id=33808", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Villa.Margot-.10"],
	["/de/03-bansin-villa-strandperle-whg-28-29?id=134637", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Strandperle-.Whg-.28-29"],
	["/de/03-bansin-villa-waldstrasse-app-14?id=112175", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Bansin/Waldstrasse-.14"],
	["/de/04-gorki-park-gp_02?id=33952", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP02"],
	["/de/04-gorki-park-gp_03?id=33954", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP03"],
	["/de/04-gorki-park-gp_04?id=33953", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP04"],
	["/de/04-gorki-park-gp_05?id=33955", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP05"],
	["/de/04-gorki-park-gp_06?id=33956", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP06"],
	["/de/04-gorki-park-gp_07?id=33957", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP07"],
	["/de/04-gorki-park-gp_08?id=33958", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP08"],
	["/de/04-gorki-park-gp_09?id=33959", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP09"],
	["/de/04-gorki-park-gp_10?id=33988", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP10"],
	["/de/04-gorki-park-gp_11?id=33989", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP11"],
	["/de/04-gorki-park-gp_12?id=33990", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP12"],
	["/de/04-gorki-park-gp_13?id=33991", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP13"],
	["/de/04-gorki-park-gp_16?id=33997", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP16"],
	["/de/04-gorki-park-gp_17?id=143711", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP17"],
	["/de/04-gorki-park-gp_18?id=33998", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP18"],
	["/de/04-gorki-park-gp_19?id=33999", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP19"],
	["/de/04-gorki-park-gp_20?id=34000", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP20"],
	["/de/04-gorki-park-gp_23?id=34008", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP23"],
	["/de/04-gorki-park-gp_24?id=34009", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Heringsdorf/GP24"],
	["/de/05-residenz-am-balmer-see-bs_01?id=33575", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS01"],
	["/de/05-residenz-am-balmer-see-bs_02?id=33580", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS02"],
	["/de/05-residenz-am-balmer-see-bs_03?id=33584", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS03"],
	["/de/05-residenz-am-balmer-see-bs_04?id=33589", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS04"],
	["/de/05-residenz-am-balmer-see-bs_05?id=33593", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS05"],
	["/de/05-residenz-am-balmer-see-bs_06?id=82452", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS06"],
	["/de/05-residenz-am-balmer-see-bs_07?id=42431", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS07"],
	["/de/05-residenz-am-balmer-see-bs_08?id=38132", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS08"],
	["/de/05-residenz-am-balmer-see-bs_09?id=40083", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS09"],
	["/de/05-residenz-am-balmer-see-bs_10?id=41918", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS10"],
	["/de/05-residenz-am-balmer-see-bs_11?id=33688", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS11"],
	["/de/05-residenz-am-balmer-see-bs_12?id=33596", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS12"],
	["/de/05-residenz-am-balmer-see-bs_13?id=33598", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS13"],
	["/de/05-residenz-am-balmer-see-bs_14?id=33600", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS14"],
	["/de/05-residenz-am-balmer-see-bs_15?id=33601", "/s#/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm"],
	["/de/05-residenz-am-balmer-see-bs_16?id=33603", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS16"],
	["/de/05-residenz-am-balmer-see-bs_18?id=33615", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS18"],
	["/de/05-residenz-am-balmer-see-bs_19?id=33616", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS19"],
	["/de/05-residenz-am-balmer-see-bs_20?id=33618", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS20"],
	["/de/05-residenz-am-balmer-see-bs_21-kleine-auszeit?id=36281", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS21"],
	["/de/05-residenz-am-balmer-see-bs_22?id=33622", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS22"],
	["/de/05-residenz-am-balmer-see-bs_23?id=33625", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS23"],
	["/de/05-residenz-am-balmer-see-bs_25?id=33626", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS25"],
	["/de/05-residenz-am-balmer-see-bs_26?id=82043", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS26"],
	["/de/05-residenz-am-balmer-see-bs_29?id=33630", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS29"],
	["/de/05-residenz-am-balmer-see-bs_30?id=33632", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS30"],
	["/de/05-residenz-am-balmer-see-bs_31?id=33634", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS31"],
	["/de/05-residenz-am-balmer-see-bs_32?id=33635", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS32"],
	["/de/05-residenz-am-balmer-see-bs_33?id=33636", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS33"],
	["/de/05-residenz-am-balmer-see-bs_34?id=34524", "/s#/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm"],
	["/de/05-residenz-am-balmer-see-bs_35?id=38236", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS35"],
	["/de/05-residenz-am-balmer-see-bs_36?id=33573", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS36"],
	["/de/05-residenz-am-balmer-see-bs_37?id=33578", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS37"],
	["/de/05-residenz-am-balmer-see-bs_38?id=33581", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS38"],
	["/de/05-residenz-am-balmer-see-bs_39?id=33582", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS39"],
	["/de/05-residenz-am-balmer-see-bs_40?id=33583", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS40"],
	["/de/05-residenz-am-balmer-see-bs_41?id=157880", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS41"],
	["/de/05-residenz-am-balmer-see-bs_42?id=33846", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS42"],
	["/de/05-residenz-am-balmer-see-bs_43?id=35961", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS43"],
	["/de/05-residenz-am-balmer-see-bs_44?id=45668", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS44"],
	["/de/05-residenz-am-balmer-see-bs_45?id=33590", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS45"],
	["/de/05-residenz-am-balmer-see-bs_46?id=43681", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS46"],
	["/de/05-residenz-am-balmer-see-bs_47?id=33591", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS47"],
	["/de/05-residenz-am-balmer-see-bs_48?id=33592", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS48"],
	["/de/05-residenz-am-balmer-see-bs_49?id=33595", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS49"],
	["/de/05-residenz-am-balmer-see-bs_50?id=33597", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS50"],
	["/de/05-residenz-am-balmer-see-bs_51?id=33599", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS51"],
	["/de/05-residenz-am-balmer-see-bs_52?id=89875", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS52"],
	["/de/05-residenz-am-balmer-see-bs_53?id=33604", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS53"],
	["/de/05-residenz-am-balmer-see-bs_54?id=33605", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS54"],
	["/de/05-residenz-am-balmer-see-bs_55?id=33606", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS55"],
	["/de/05-residenz-am-balmer-see-bs_56?id=33607", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS56"],
	["/de/05-residenz-am-balmer-see-bs_57?id=33627", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS57"],
	["/de/05-residenz-am-balmer-see-bs_58?id=38196", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS58"],
	["/de/05-residenz-am-balmer-see-bs_60?id=33771", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS60"],
	["/de/05-residenz-am-balmer-see-bs_61?id=33610", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS61"],
	["/de/05-residenz-am-balmer-see-bs_62?id=33612", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS62"],
	["/de/05-residenz-am-balmer-see-bs_63?id=33614", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS63"],
	["/de/05-residenz-am-balmer-see-bs_65?id=33617", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS65"],
	["/de/05-residenz-am-balmer-see-bs_66?id=33619", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS66"],
	["/de/05-residenz-am-balmer-see-bs_67?id=33621", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS67"],
	["/de/05-residenz-am-balmer-see-bs_68?id=119944", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS68"],
	["/de/05-residenz-am-balmer-see-bs_69?id=33623", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS69"],
	["/de/05-residenz-am-balmer-see-bs_70?id=33624", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS70"],
	["/de/05-residenz-am-balmer-see-bs_71?id=33629", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Balm/BS71"],
	["/de/07-ferienwohnung-am-achterwasser-2?id=142442", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ueckeritz/Achterwasser-.2"],
	["/de/07-ferienwohnung-biene?id=121189", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ueckeritz/Biene"],
	["/de/07-ferienwohnung-hering?id=82512", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ueckeritz/Hering"],
	["/de/07-ferienwohnung-ostseestern?id=149205", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ueckeritz/Ostseestern-.Ueckeritz"],
	["/de/07-ferienwohnung-sonneninsel?id=154517", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ueckeritz/Sonneninsel-.Ueckeritz"],
	["/de/07-haus-stortebeker?id=68369", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ueckeritz/Stoertebeker-.Ueckeritz"],
	["/de/07-uckeritz-fischerblick?id=155113", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Ueckeritz/Fischerblick-.Ueckeritz"],
	["/de/08-fischerhus-mit-sauna-und-kamin?id=128901", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Fischerhus"],
	["/de/08-fritz-reethus-mit-sauna-und-kamin?id=134232", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Fritz.Reethus"],
	["/de/08-haus-berlin-krabbengalerie?id=107307", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Berlin-.Krabbengalerie"],
	["/de/08-haus-berlin-loddiner-hoft?id=107305", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Berlin-.Loddiner.Hoeft"],
	["/de/08-haus-berlin-wohnung-02?id=157742", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Berlin-.Whg-.2"],
	["/de/08-haus-ostseegluck-wohnung-abendsonne?id=125565", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Ostseeglueck-.Abendsonne"],
	["/de/08-haus-ostseegluck-wohnung-mittagssonne?id=125583", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Ostseeglueck-.Mittagssonne"],
	["/de/08-haus-ostseegluck-wohnung-morgensonne?id=125578", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Ostseeglueck-.Morgensonne"],
	["/de/08-haus-ostseegluck-wohnung-ostseesonne?id=125512", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Ostseeglueck-.Ostseesonne"],
	["/de/08-heide-hus-usedom-mit-sauna?id=129823", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Heide.Hus"],
	["/de/08-hygge-hus-loddin-mit-sauna?id=129830", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Hygge.Hus"],
	["/de/08-loddin-ferienhaus-achterwasserblick-we-1?id=152889", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Achterwasserblick-.01"],
	["/de/08-loddin-ferienhaus-achterwasserblick-we-2?id=152893", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Achterwasserblick-.02"],
	["/de/08-loddin-ferienhaus-achterwasserblick-we-4?id=152895", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Achterwasserblick-.04"],
	["/de/08-loddinperle-mit-sauna?id=132847", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Loddinperle"],
	["/de/08-lodino-lodge-mit-sauna-und-kamin?id=127129", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Lodino.Lodge"],
	["/de/08-reethus-54-degree-loddin-mit-sauna?id=125274", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Loddin/Reethus.54-"],
	["/de/09-ferienhaus-matthies?id=72747", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/Matthies-.Ferienhaus"],
	["/de/09-fuurtoorn-mit-fass-sauna?id=123085", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Stubbenfelde/Fueuertoorn"],
	["/de/09-kleiner-wagen-stubbenfelde?id=80887", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Stubbenfelde/Kleiner.Wagen"],
	["/de/09-schwalbennest?id=106566", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/Schwalbennest"],
	["/de/09-seehaus-lutt-marten?id=126345", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Stubbenfelde/Seehaus.Luett.Marten"],
	["/de/09-seehaus-sot-mine?id=126338", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Stubbenfelde/Seehaus.Soet.Mine"],
	["/de/09-strandkorv-mit-fass-sauna?id=123093", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Stubbenfelde/Strandkoerv"],
	["/de/09-strandperle?id=127137", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/Strandperle-.Koelpinsee"],
	["/de/09-villa-daheim-01?id=45344", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/VD-.01"],
	["/de/09-villa-daheim-02?id=47560", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/VD-.02"],
	["/de/09-villa-daheim-03?id=47563", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/VD-.03"],
	["/de/09-villa-daheim-04?id=47853", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/VD-.04"],
	["/de/09-villa-daheim-05?id=47856", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/VD-.05"],
	["/de/09-villa-daheim-06?id=47857", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/VD-.06"],
	["/de/09-villa-daheim-07?id=47863", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/VD-.07"],
	["/de/10-bernsteinquartier-bq0108-mister-bernstein?id=155075", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0108"],
	["/de/10-bernsteinquartier-bq0225?id=153485", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0225"],
	["/de/10-bernsteinquartier-bq0440-apartment-mit-infrarotsauna?id=149838", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0440"],
	["/de/10-bernsteinquartier-bq0446-movennest?id=149145", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0446"],
	["/de/10-bernsteinquartier-bq0447?id=150978", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0447"],
	["/de/10-bernsteinquartier-bq0548?id=147154", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0548"],
	["/de/10-bernsteinquartier-bq0549-feriengluck?id=148328", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0549"],
	["/de/10-bernsteinquartier-bq0550?id=141522", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0550"],
	["/de/10-bernsteinquartier-bq0551?id=144203", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0551"],
	["/de/10-bernsteinquartier-bq0553?id=152315", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0553"],
	["/de/10-bernsteinquartier-bq0554-lieblingsplatz?id=140824", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0554"],
	["/de/10-bernsteinquartier-bq0555?id=140322", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0555"],
	["/de/10-bernsteinquartier-bq0556-meeresgold?id=141248", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0556"],
	["/de/10-bernsteinquartier-bq0557-bonjour?id=143647", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0557"],
	["/de/10-bernsteinquartier-bq0558-eichenkrone?id=149433", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0558"],
	["/de/10-bernsteinquartier-bq0559?id=144463", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0559"],
	["/de/10-bernsteinquartier-bq0561-ostseeliegeplatz61?id=147596", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ0561"],
	["/de/10-bernsteinquartier-bq06b-haus-lachmowe?id=139195", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ06b"],
	["/de/10-bernsteinquartier-bq06e?id=139612", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ06e"],
	["/de/10-bernsteinquartier-bq07b-ferienhaus-windspiel?id=138934", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ07b"],
	["/de/10-bernsteinquartier-bq07e-ferienhaus-meerzeit?id=137020", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ07e"],
	["/de/10-bernsteinquartier-bq08c-haus-nordhorn?id=138301", "/s"],
	["/de/10-bernsteinquartier-bq09a-ferienhaus-meeresrauschen?id=143881", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ09a"],
	["/de/10-bernsteinquartier-bq09b-ostseeparadies?id=136997", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ09b"],
	["/de/10-bernsteinquartier-bq09c-ostseenest?id=137562", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ09c"],
	["/de/10-bernsteinquartier-bq10b?id=140324", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ10b"],
	["/de/10-bernsteinquartier-bq10c?id=137563", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ10c"],
	["/de/10-bernsteinquartier-bq1182?id=139867", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ1182"],
	["/de/10-bernsteinquartier-bq1184-ferienwohnung-strandlust?id=137019", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ1184"],
	["/de/10-bernsteinquartier-bq1186?id=139038", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ1186"],
	["/de/10-bernsteinquartier-bq1188?id=137521", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ1188"],
	["/de/10-bernsteinquartier-bq1190?id=137018", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koelpinsee/BQ1190"],
	["/de/11-ferienwohnung-aurum?id=131660", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koserow/Aurum"],
	["/de/11-ferienwohnung-maus?id=148069", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koserow/Maus"],
	["/de/11-koserow-villa-ostseestrand?id=153909", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koserow/Ostseestrand-.Villa"],
	["/de/11-seeblick_221?id=141616", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koserow/Seeblick-.221"],
	["/de/11-seeblick_222?id=142216", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koserow/Seeblick-.222"],
	["/de/11-seeblick_224?id=141619", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koserow/Seeblick-.224"],
	["/de/11-waterblick_2-01?id=123558", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Koserow/Waterblick-.201"],
	["/de/12-bungalow-sonneninsel?id=121139", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zempin/Sonneninsel-.Zempin"],
	["/de/12-ferienwohnung-jannek?id=110367", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zempin/Jannek"],
	["/de/12-ferienwohnung-seestrasse?id=81328", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zempin/Seestrasse-.FeWo"],
	["/de/12-residenz-waldhaus-whg-05?id=78263", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zempin/Waldhaus-.05"],
	["/de/12-villa-inselliebe-mit-sauna?id=113981", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zempin/Inselliebe"],
	["/de/12-villa-kiesel?id=68495", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zempin/Kiesel"],
	["/de/12-villa-maarten?id=68502", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zempin/Maarten"],
	["/de/13-ferienwohnung-ahorn?id=110504", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Ahorn"],
	["/de/13-ferienwohnung-blei?id=80795", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Blei"],
	["/de/13-ferienwohnung-dohner-wohnung-1?id=48217", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Doehner-.01"],
	["/de/13-ferienwohnung-dohner-wohnung-2?id=48448", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Doehner-.02"],
	["/de/13-ferienwohnung-dohner-wohnung-3?id=49513", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Doehner-.03"],
	["/de/13-ferienwohnung-dohner-wohnung-4?id=49524", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Doehner-.04"],
	["/de/13-ferienwohnung-dune-25-1?id=82622", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Duene.25-.1"],
	["/de/13-ferienwohnung-gunsilius?id=72641", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Gunsilius"],
	["/de/13-ferienwohnung-sonne?id=96700", "/s"],
	["/de/13-ferienwohnung-strand-8?id=133554", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Strand-.8"],
	["/de/13-hansehus-whg-1?id=134398", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Hansehus-.01"],
	["/de/13-hansehus-whg-2?id=134384", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Hansehus-.02"],
	["/de/13-hansehus-whg-3?id=134379", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Hansehus-.03"],
	["/de/13-hansehus-whg-4?id=134431", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Hansehus-.04"],
	["/de/13-hansehus-whg-5?id=134425", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Hansehus-.05"],
	["/de/13-haus-seeadler-whg-habicht?id=114671", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Seeadler-.Habicht"],
	["/de/13-haus-seeadler-whg-seeschwalbe?id=150973", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Seeadler-.Seeschwalbe"],
	["/de/13-meerferienhaus-haus-schlehenweg?id=137065", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Schlehenweg-.Ferienhaus"],
	["/de/13-meerferienhaus-leselust?id=154246", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Leselust"],
	["/de/13-meerferienhaus-wellnesshaus?id=137069", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Wellnesshaus"],
	["/de/13-ostseequartett-casa-baltica?id=137223", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Ostseequartett-.Casa.Baltica"],
	["/de/13-ostseequartett-s-tr-and-im-schuh-wohnung-24?id=157365", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Ostseequartett-.Whg-24.S-tr-and-im-Schuh"],
	["/de/13-ostseequartett-whg-mowennest?id=113776", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Ostseequartett-.Moewennest"],
	["/de/13-ostseequartett-wohnung-7?id=154808", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Ostseequartett-.Whg-.7"],
	["/de/13-villa-lohengrin?id=133701", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Lohengrin"],
	["/de/13-villa-tusculum-whg-1?id=120706", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Tusculum-.01"],
	["/de/13-villa-tusculum-whg-possin?id=150594", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Tusculum-.Possin"],
	["/de/13-zinnowitz-ferienappartements-haus-vincent-wohnung-1?id=153527", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Vincent.1"],
	["/de/13-zinnowitz-ferienappartements-haus-vincent-wohnung-2?id=153540", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Vincent.2"],
	["/de/13-zinnowitz-ferienappartements-haus-vincent-wohnung-3?id=153542", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Vincent.3"],
	["/de/13-zinnowitz-ferienappartements-haus-vincent-wohnung-4?id=153543", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Vincent.4"],
	["/de/13-zinnowitz-ferienappartements-haus-vincent-wohnung-5?id=153544", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Vincent.5"],
	["/de/13-zinnowitz-ferienappartements-haus-vincent-wohnung-7?id=153546", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Vincent.7"],
	["/de/13-zinnowitzer-hof-ferienwohnung-kustenwald-wohnung-04?id=117439", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Zinnowitzer.Hof-.Kuestenwald"],
	["/de/13-zinnowitzer-hof-ferienzimmer-kustensonne-wohnung-03?id=130571", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Zinnowitz/Zinnowitzer.Hof-.Kuestensonne"],
	["/de/15-bella-vita?id=93517", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Bella.Vita"],
	["/de/15-bungalow-jonas?id=117408", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Jonas"],
	["/de/15-bungalow-konrad?id=117409", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Konrad"],
	["/de/15-ferienhaus-brakkelmann-birkenhain?id=110415", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Brakkelmann"],
	["/de/15-ferienhaus-zur-frischen-brise-whg-1?id=86150", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Frische.Brise-.01"],
	["/de/15-ferienhaus-zur-frischen-brise-whg-2?id=86315", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Frische.Brise-.02"],
	["/de/15-ferienwohnung-carlotta?id=124826", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Carlotta"],
	["/de/15-ferienwohnung-eichhorst-whg-3?id=51531", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Eichhorst-.03"],
	["/de/15-ferienwohnung-emilia?id=124780", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Emilia"],
	["/de/15-sonnenschein-leuchtfeuer?id=141880", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Sonnenschein-.Leuchtfeuer"],
	["/de/15-sonnenschein-seekiste?id=142232", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Sonnenschein-.Seekiste"],
	["/de/15-sonnenschein-seepferdchen?id=142243", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Trassenheide/Sonnenschein-.Seepferdchen"],
	["/de/16-ferienhaus-am-eikboom-1-mit-fass-sauna?id=68155", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Eikboom-.01"],
	["/de/16-ferienhaus-am-eikboom-2-mit-fass-sauna?id=68244", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Eikboom-.02"],
	["/de/16-ferienhaus-haus-zum-kapitan?id=121788", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Kapitaen-.Ferienhaus"],
	["/de/16-ferienhaus-karl-mit-sauna-elysium?id=133891", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Karl"],
	["/de/16-ferienhaus-krabbe?id=83420", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Krabbe"],
	["/de/16-ferienhaus-rosa-mit-sauna-elysium?id=133943", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Rosa"],
	["/de/16-ferienhaus-seestern?id=83425", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Seestern"],
	["/de/16-ferienhaus-waldoase-1?id=137176", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Waldoase-.01"],
	["/de/16-ferienhaus-waldoase-2?id=137368", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Waldoase-.02"],
	["/de/16-haus-viktoria-am-see?id=123643", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom/Karlshagen/Viktoria"],
	["/de/ferieninsel-usedom/", "/Deutschland/Ostsee/Mecklenburg-Vorpommern/Insel.Usedom"],
	["/de/ferienwohnungen/", "/s"],
	["/de/merkliste", "/s#?favorites=true"],
	["/de/request?id=121788", "/s"],
	["/de/request?id=148218", "/s"],
	["/de/request?id=33601", "/s"],
	["/de/unser-service/", "/service-fur-unsere-gaste"]
]);


// console.log("pathname", pathname);
// console.log("redirects", redirects);
// console.log("redirects.has(pathname)", redirects.has(pathname));

// Check if the current URL (pathname + search) matches any key in the redirects map
const currentPath = pathname + search;

if (redirects.has(currentPath)) {
	// Redirect to the new path without the search parameters
	location.href = origin + redirects.get(currentPath);
}